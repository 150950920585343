import React from 'react';
import styles from "./TopBar.module.scss";
import Link from "next/link";
import { Image } from "primereact/image";
import cn from "classnames";
import { useIsBrowser } from "../../../hooks/useIsBrowser";
import Tippy from "@tippyjs/react";
import { useI18nLocaleFormat } from "../../../hooks/useI18nLocaleFormat";

const HomeLink = ({ themeBrightness, disableTips, workspaceOrSlugId }) => {
    const isBrowser = useIsBrowser();
    const { localeAs } = useI18nLocaleFormat();

    return (
        <div className={styles.layout__topbar_logo}>
            <Link href={`/${workspaceOrSlugId}`}>
                <a>
                    {
                        isBrowser &&
                        <Image
                            src={themeBrightness === 'light' ? '/img/jugru_group.svg' : '/img/jugru_group-white.svg'}
                            height="100"
                            alt="logo"
                        />
                    }
                    <Tippy
                        content={localeAs("general.home")}
                        disabled={disableTips}
                    >
                        <button
                            className={cn("p-link", styles.layout__topbar_button, styles.layout__topbar_home_button)}>
                            <i className="pi pi-home"/>
                        </button>
                    </Tippy>
                </a>
            </Link>
        </div>
    );
};

export default HomeLink;
