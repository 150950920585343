import React, { FC } from 'react';
import LanguageBar from "./LanguageBar";
import styles from "./TopBar.module.scss";
import cn from "classnames";
import Profile from "./Profile";
import HelpBar from "./HelpBar";
import DarkLightBar from "./DarkLightBar";
import HomeLink from "./HomeLink";
import { useDataContext } from "../../../data/DataContext";
import { observer } from "mobx-react-lite";

interface TopBarProps {
    workspaceOrSlugId: string;
}

const TopBar: FC<TopBarProps> = ({ workspaceOrSlugId }) => {
    const { disableTips, themeBrightness } = useDataContext().stores.appStateStore.appState;

    const rightContents = () => (
        <ul className={cn(styles.layout__topbar_menu, "lg:flex origin-top", { 'layout-topbar-menu-mobile-active': false })}>
            <HelpBar disableTips={disableTips}/>
            <DarkLightBar/>
            <LanguageBar disableTips={disableTips}/>
            <Profile/>
        </ul>
    );

    return (
        <div className={cn(styles.layout__topBar)}>
            <HomeLink disableTips={disableTips} themeBrightness={themeBrightness}
                      workspaceOrSlugId={workspaceOrSlugId}/>
            {
                rightContents()
            }
        </div>
    );
};

export default observer(TopBar);
